<template>
  <div class="sms-poster">
    <canvas id="posterHtml"></canvas>
    <img width="100%" height="655px" :src="saveImgURL" alt="" />
  </div>
</template>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<script>
import axios from 'axios'
import miniProgramModel from '../../model/miniprogram/index'
import { Loading } from 'vant'
import WechatCardModel from '@/model/wechat-card/index'
import { downloadPic } from '../../utils/wxcard-canvas.js'
const posterImg = 'http://img.laoban100.com/WeChatCardTicket/poster-bgc.png'
export default {
  components: { [Loading.name]: Loading },
  data () {
    return {
      uploadOption: {},
      saveImgURL: '',
      imgLoading: true,
      canvas: null,
      cardDetail: {},
      posterQr: ''
    }
  },
  created () {
  },
  async mounted () {
    const { accId } = this.$route.query
    await WechatCardModel.getPosterQr(accId).then(res => {
      this.posterQr = res.data.qrcodeUrl
    })
    await WechatCardModel.getCardInfo(accId).then(res => {
      if (res.code !== 500) {
        this.cardDetail = res.data
      }
    })
    this.createPoster()
  },
  methods: {
    todetail () {
      this.$router.push({ name: 'wxcardInviteDetail' })
    },
    blobToFile (theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async getSignature () {
      // canvas图片base64 转 File 对象
      const dataURL = this.canvas.toDataURL('image/png')
      const arr = dataURL.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let obj = new Blob([u8arr], { type: mime })
      obj = this.blobToFile(obj, 'a.png')
      const formData = new FormData()
      formData.append('file', obj)
      // 获取文件后缀
      const suffix = formData.get('file').type.split('/')[1]
      const data = await miniProgramModel.getSign({ suffix: suffix })
      if (data.code === 0) {
        this.uploadOption.path = data.data.uploadUrl
        formData.append('policy', data.data.policy)
        formData.append('signature', data.data.signStr)
        formData.append('bucketName', 'img-i200')
        this.updateImg(formData)
      }
    },
    // 上传
    updateImg (formData) {
      axios({
        url: 'https://v1.api.upyun.com/img-i200/',
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
      }).then(res => {
        if (res.data.code === 200) {
          const url = res.data.url
          this.saveImgURL = 'http://img.laoban100.com' + url
          this.imgLoading = false
        }
      })
    },
    async createPoster () { // 生成canvas海报
      const canvas = document.getElementById('posterHtml')
      const ctx = canvas.getContext('2d')
      downloadPic(canvas, ctx, posterImg, this.cardDetail, this.posterQr, true)
      setTimeout(() => {
        this.canvas = canvas
        this.getSignature()
      }, 500)
    },
  }
  // watch: {
  //   saveImgURL (v) {
  //     if (v) {
  //       this.imgLoading = false
  //     }
  //   }
  // }
}
</script>
